import React, { useState } from "react";
import styles from "./hero.module.css";
import Mask from "../../../assets/mask.svg";
import HeroModels from "../../../assets/HeroModels.png";
import HeroImg from "../../../assets/HeroImg.png";
import IOSimage from "../../../assets/IOS.png";
import AndroidImage from "../../../assets/android.png";
import Modal from "../../../Components/Modals/Modal";
// import DownloadMetaPixel from "../../downloadCTA";
import ReactPixel from 'react-facebook-pixel';

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMacOS = navigator.platform.toUpperCase().includes("MAC");
  const isAndroid = /Android/.test(navigator.userAgent);
  const isiPhone = /iPhone/.test(navigator.userAgent);
  const isWindows = /Win/.test(navigator.platform);
  const isMacOSOriPhone = isMacOS || isiPhone;

  const openModal = () => {
    setIsModalOpen(true);
    handleLeadClick()
  };
  const handleLeadClick = () => {
    // if (window.fbq) {
    //   window.fbq('track', 'Lead', {
    //     content_name: 'Lead Conversion'
    //   });
    //   console.log('Lead event fired');
    // } else {
    //   console.error('Facebook Pixel not initialized');
    // }
    ReactPixel.track('Lead');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* <DownloadMetaPixel /> */}

      <div className={styles.hero} id="hero">
        <img src={Mask} alt="Mask" className={styles.mask} />
        <div className={styles.heroWrapper}>
          <div className={styles.heroLeftContent}>
            <div className={styles.heroText}>
              <img
                src={HeroImg}
                alt="Hero"
                className={styles.heroImg}

              />

              {/* <span className={styles.LineBox}> */}
              <span className={styles.firstLine}>They Love </span>
              <br className={styles.onMobile} />
              <span className={styles.firstLine}>The Players,</span>
              {/* </span> */}
              <br />
              <span className={styles.secondLine}>We Love </span>
              <br className={styles.onMobile} />
              <span className={styles.secondLine}>The Game.</span>
            </div>

            <div className={`${styles.heroButtons} ${styles.onDesktop}`}>
              <button className={styles.heroButton} onClick={openModal}>
                <a
                  href="/Tricket.apk"
                  download
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  Download For
                  <img
                    src={AndroidImage}
                    alt="Android"
                    className={styles.heroBtnDownImg}
                  />
                </a>
              </button>

              <button className={styles.heroButton}>
                <a href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965">
                  <img src={IOSimage} alt="IOS" className={styles.heroBtnImg} />
                </a>
              </button>
            </div>
            <div className={`${styles.heroButtons} ${styles.onMobile}`}>
              {isMacOSOriPhone ? (
                <a
                  href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                  onClick={handleLeadClick}

                >
                  <button className={styles.heroButton}>Download Now</button>
                </a>
              ) : (
                <a
                  href="/Tricket.apk"
                  download
                  onClick={openModal}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <button className={styles.heroButton} onClick={openModal}>
                    Download Now
                  </button>
                </a>
              )}


              {/* {isMacOSOriPhone && (
              <a
                href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                <button className={styles.heroButton} onClick={openModal}>
                  Download Now
                </button>
              </a>
            )}
            {isAndroid && (
              <a
                href="/Tricket.apk"
                download
                onClick={openModal}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                <button className={styles.heroButton} onClick={openModal}>
                  Download Now
                </button>
              </a>
            )} */}

              {/* {isiPhone && (
              <a
                href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                <button className={styles.heroButton} onClick={openModal}>
                  Download Now
                </button>
              </a>
            )} */}

              {/* {isWindows && (
              <a
                href="/Tricket.apk"
                download
                onClick={openModal}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                <button className={styles.heroButton} onClick={openModal}>
                  Download Now
                </button>
              </a>
            )} */}

              {/* <button className={styles.heroButton} onClick={openModal}>
              <a
                href="/Tricket.apk"
                download
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                Download Now
                <img
                src={AndroidImage}
                alt="Android"
                className={styles.heroBtnDownImg}
              />
              </a>
            </button> */}
              {/*             
            <button className={styles.heroButton}>
            <a href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965">
              <img src={IOSimage} alt="IOS" className={styles.heroBtnImg} />
              </a>
            </button> */}

            </div>
          </div>
          <div className={styles.heroRightContent}>
            <img
              src={HeroModels}
              alt="Hero Models"
              className={styles.heroModels}
            />
          </div>
        </div>


        <Modal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </>
  );
};

export default Hero;
