import React from "react";
import classes from "../Privacy/Privacy.module.css";
const Responsible = () => {
  return (
    <>
      <div className={classes.container}>
        <h1>Responsible Game Play</h1>
        {/* <p>
          Your privacy is important to us, so Tricket, has created the following
          Privacy Policy (“Policy”) to let you know what information we collect
          when you visit our Website https://tricket.in (“Website”) and also our
          Applicaton, why we collect it and how we use it.
        </p> */}
      </div>
      <div className={classes.container2}>
        <div>
          {/* <h3>H1 Header</h3> */}
          <p>
          As a fantasy cricket gamer, it is essential to practice responsible gameplay to ensure your experience is enjoyable and safe. Here are a few things to keep in mind:

          </p>
        </div>
        <div>
        {/* <p><strong>AGREEMENT TO TERMS</strong></p> */}


<ul>
<li>The player must be 18 or older to play skill games for real money.
</li>



<li>A person, either a resident of Andhra Pradesh, Assam, Nagaland, Sikkim, or Telangana, will not be permitted to proceed to sign up for any match in the paid version of the contest. They can only join the practice or free session.
</li>



<li>It is easy to get caught up in the excitement of the game but keep a watch.
</li>



<li>Don’t let fantasy cricket gaming interfere with your responsibilities. Before engaging in fantasy cricket gaming, prioritize your work and family commitments.
</li>



<li>Be aware of your emotional state while playing. Fantasy cricket gaming should be a source of entertainment, not stress or anxiety. If you are getting too invested or upset while playing, take a break and return to the game when you feel more balanced.
</li>



<li>This game may be habit-forming and financially risky. Play responsibly.
</li>

</ul>


<p>By following these tips, you can ensure that your fantasy cricket gaming experience is enjoyable and responsible.</p>

        </div>
      </div>
    </>
  );
};

export default Responsible;
