import classes from "./WhyTricket.module.css";
import compass from "../../../assets/compass.png";
import realtime from "../../../assets/realtime.png";
import withdraw from "../../../assets/withdraw.png";
import userFriendly from "../../../assets/user-friendly.png";
import support from "../../../assets/support.png";
const WhyTricket = () => {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h2>Why Tricket?</h2>
        <div className={classes.card_container}>
          <div className={classes.card}>
            <div className={classes.inner__div}>
              <img src={compass} alt="compass" />
            </div>
            <h4>Join & Project</h4>
            <p>The score of upcoming SPELL of matches of your choice</p>
          </div>
          <div className={classes.card}>
            <div className={classes.inner__div}>
              <img src={realtime} alt="realtime" />
            </div>
            <h4>Compete</h4>
            <p>With thousands of players & Win Cash Prize every 15 mins</p>
          </div>
          <div className={classes.card}>
            <div className={classes.inner__div}>
              <img src={support} alt="support" />
            </div>
            <h4>24 X 7 Support</h4>
            <p>Our support is here to help you round the clock</p>
          </div>
          <div className={classes.card}>
            <div className={classes.inner__div}>
              <img src={withdraw} alt="withdraw" />
            </div>
            <h4>Instant Withdrawals</h4>
            <p>Get your KYC & Withdrawal approval instantly</p>
          </div>
          <div className={classes.card}>
            <div className={classes.inner__div}>
              <img src={realtime} alt="realtime" />
            </div>
            <h4>Play Real-time</h4>
            <p>
              Unique fantasy cricket with live stats for immersive gameplay.
            </p>
          </div>
          <div className={classes.card}>
            <div className={classes.inner__div}>
              <img src={userFriendly} alt="userFriendly" />
            </div>
            <h4>User Friendly & Exciting</h4>
            <p>
              Skill meets joy: Predict, play, and win real cash effortlessly!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyTricket;
