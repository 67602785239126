import React, { useEffect } from "react";
import styles from "./home.module.css";
import Hero from "./Hero/hero";
import Testimonials from "./Testimonials/testimonials";
import HowToPlay from "./HowToPlay/HowToPlay";
import WhyTricket from "./WhyTricket/WhyTricket";
// import PageLoadPixel from "../PageLoadPixel";
import ReactPixel from 'react-facebook-pixel';


const Home = () => {
  useEffect(() => {
    console.log('Initializing Facebook Pixel pageview');
    ReactPixel.init('1121861205709964');
    ReactPixel.pageView(); // Track initial page load
  }, []);

  return (
    <div className={styles.home}>
      {/* <PageLoadPixel /> */}
      <Hero />
      <HowToPlay />
      <WhyTricket />
      <Testimonials />
    </div>
  )
};

export default Home;
