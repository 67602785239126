import React, { useState } from "react";
import classes from "./faq.module.css";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion, { accordionClasses } from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import img from "../../assets/Group 1057.png";
import { Typography } from "@mui/joy";

const FAQ = () => {
  const [expanded, setExpanded] = useState({});
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  // FAQ data array
  const faqData = [
    {
      question: "What are Real-time fantasy Sports?",
      answer: "Real-time Fantasy sports is a skill-based online sports game where you can project how circumstances would change by forecasting the score (runs and wickets) of a SPELL as well as the overs of an ongoing or live match, using your analytical skills, and competing with real players. You earn points and win cash prizes based on how close you get to the actual score in live matches.",
    },
    {
      question: "What is a Spell?",
      answer: "An inning of a cricket match is divided into different Spells. And a Spell is a set of 4 or 5 overs. For Example, A T20 match is having a total of 8 Spells, 4 for each inning and a one-day match is having a total of 20 spells, 10 for each inning.",
    },
    {
      question: "Is it safe to add money to Tricket?",
      answer: "Adding money to your Tricket wallet account is both simple and safe. We have many different payment options enabled on Tricket to ensure that your personal details are safe with us. What's more? After you verify your personal details, you can withdraw the money that you win on Tricket directly to your bank account.",
    },
    {
      question: "How do Tricket points work?",
      answer: "Tricket has a unique pointing system that is calculated on the basis of how close your projection or analysis will be with the actual score scored in a spell time of a live match. You can check the Pointing system in the App for more details.",
    },
    {
      question: "When do I get my winning?",
      answer: "If you process a withdrawal request after verifying your details via KYC, it should be credited after some time or within 3 to 5 working days.",
    },
    {
      question: "How can I get the Tricket app for my phone?",
      answer: "You can download the Tricket App directly from our website and IOS users can get this directly from the APP store.",
    },
    {
      question: "Which type of contest can I Join?",
      answer: "Select the match, click the team for which you want to play the spell, choose the spell, analyze the conditions, using your skill project the score you think will be scored in that spell, and join any contest of your choice to compete with other players and win cash prizes. You can also join paid contests by paying the entry fee or sharpen your skills by entering practice contests before competing with others.",
    },
    {
      question: "How to play on the Tricket App?",
      answer: "Download the Tricket App and then sign up using your Gmail or Phone number. Then follow the following steps to play a spell: Choose a match, choose your team, and then select the Spell. Analyze the Game and Forecast your Score of the Selected Spell. Join a room of your choice and then Forecast your Scores for the Overs of that Spell. Once the Spell Ends, the Points Scored throughout the Spell Decide the Winner.",
    },
    {
      question: "I haven’t received my withdrawal money, what should I do?",
      answer: "Sit back and relax, If you process a withdrawal request after verifying your details via KYC, it should be credited after some time or within 3 to 5 working days to your verified bank account.",
    },
    {
      question: "I can’t see my added money, what should I do?",
      answer: "Please wait for a couple of minutes. If your deposits get canceled, rest assured you will receive a refund immediately.",
    },
    {
      question: "I want to withdraw, how do I verify?",
      answer: "Before you request your first withdrawal, follow the Know Your Customer (KYC) process and submit the required documents in order to verify your details. Once verified successfully, you can request a withdrawal of your winning amount anytime.",
    },
    {
      question: "How do I withdraw my winnings?",
      answer: "Click on the wallet and click on withdraw. Enter the amount you want to send, and that’s it. You will get the amount in your verified bank account.",
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.faq_container}>
        <div className={classes.faq_grid_item}>
          <h2>Frequently Asked Questions</h2>
          <AccordionGroup
            sx={{
              width: "100%",
              height: "100%",
              [`& .${accordionClasses.root}`]: {
                marginTop: "10px",
                minHeight: "48px",
                transition: "0.2s ease",
                backgroundColor: "#F0E8FF",
                borderRadius: "10px",
                borderBottom: "1px solid",
                borderColor: "background.level2",
                '& button:not([aria-expanded="true"])': {
                  transition: "0.2s ease",
                  paddingBottom: "0.625rem",
                },
                "& button:hover": {
                  background: "transparent",
                },
              },
              [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
                bgcolor: "#5715D1",
                borderRadius: "md",
                borderBottom: "1px solid",
                borderColor: "background.level2",
                color: "blue !important",
              },
              '& [aria-expanded="true"]': {
                boxShadow: (theme) =>
                  `inset 0 -1px 0 ${theme.vars.palette.divider}`,
              },
            }}
          >
            {faqData.map((faqItem, index) => (
              <Accordion
                key={index}
                expanded={expanded[`panel${index}`]}
                onChange={handleAccordionChange(`panel${index}`)}
              >
                <AccordionSummary>
                  <Typography
                    style={{
                      color: expanded[`panel${index}`] ? "white" : "black",
                    }}
                    sx={{
                      fontFamily: "Be Vietnam Pro",
                      fontSize: "18px",
                      fontWeight: 500,
                      lineHeight: "22.77px",
                      letterSpacing: "-0.01em",
                      textAlign: "left",
                      alignItems: "center",
                      display: "flex",
                      height: "48px",
                    }}
                  >
                    {faqItem.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ color: "white" }}>
                    {faqItem.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionGroup>
        </div>
        <div className={classes.faq_grid_item}>
          <img src={img} alt="FAQ" />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
