import React from "react";
import classes from "./Privacy.module.css";

const Privacy = () => {
  return (
    <>
      <div className={classes.container}>
        <h1>Privacy Policy</h1>
        <p>
          Your privacy is important to us, so Tricket, has created the following
          Privacy Policy (“Policy”) to let you know what information we collect
          when you visit our Website https://tricket.in (“Website”) and also our
          Applicaton, why we collect it and how we use it.
        </p>
      </div>
      <div className={classes.container2}>
        <div>
          <p>
            <span>
              {" "}
              Your privacy is important to us, so Tricket, has created the
              following Privacy Policy (“Policy”) to let you know what
              information we collect when you visit our Website
              https://tricket.in (“Website”) and also our Applicaton, why we
              collect it and how we use it.
            </span>
            <p>
              The terms “You,” “Your,” “Yours” and “User” refer to the
              entity/person/organization using our Website and application.
            </p>
            <p>
              When this Policy mentions “We”, “Us,” and “Our” it refers to
              Tricket and its subsidiaries and affiliates.
            </p>
            <p>
              For any questions regarding this Policy or any requests regarding
              the processing of personal data, please contact us at{" "}
              <a href="mailto:team@tricket.in">team@tricket.in</a>
            </p>
            <p>In this privacy notice, if we refer to:</p>
            <ul>
              <li>
                “<span>Website</span>
                ,” we are referring to any website of ours that references or
                links to this policy
              </li>
            </ul>
            <ul>
              <li>
                “<span>App</span>
                ,” we are referring to any application of ours that references
                or links to this policy
              </li>
            </ul>
            <ul>
              <li>
                “<span>Services</span>
                ,” we are referring to our Website, App, and other related
                services provided from our end
              </li>
            </ul>
            <p>
              The purpose of this privacy notice is to explain to you in the
              clearest way possible what information we collect, how we use it,
              and what rights you have in relation to it. If there are any terms
              in this privacy notice that you do not agree with, please
              discontinue use of our Services immediately.
            </p>
            <ol>
              <li>
                <span>WHAT INFORMATION DO WE COLLECT?</span>
              </li>
            </ol>
            <p>
              <span>Personal information you disclose to us</span>
            </p>
            <p>
              <span>
                <em>In Short: </em>
              </span>
              <em>We collect personal information that you provide to us.</em>
            </p>
            <p>
              We collect all personal information that you voluntarily provide
              to us with your consent when you register on the Services, express
              an interest in obtaining information about us or our products and
              Services, when you participate in activities on the Services or
              otherwise when you contact us.
            </p>
            <p>
              The personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make
              and the products and features you use. The personal information we
              collect may include the following:
            </p>
            <p>
              <span>Payment Data.</span> To order and use our services we may
              require you to provide certain financial information to facilitate
              the processing of payments. We will not store or collect your
              payment card details. That information is provided directly to our
              third-party payment processors whose use of your Personal
              information is governed by their Privacy Policy. All payment data
              is stored by RazorPay. You may find their privacy policy link(s)
              here:{" "}
              <a href="https://razorpay.com/privacy/">
                https://razorpay.com/privacy/
              </a>
              .
            </p>
            <p>
              <span>Social Media Login Data. </span>
              We may provide you with the option to register with us using your
              social media account details. If you choose to register in this
              way, we will collect the information described in the section
              called “
              <a href="https://tricket.in/privacy-policy/#sociallogins">
                HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </a>
              ” below.
            </p>
            <p>
              All persnal information that you provide to us must be true,
              complete and authentic, and you must notify us of any changes to
              such personal information.<u></u>
              <span>Information automatically collected</span>
            </p>
            <p>
              <span>
                <em>In Short: </em>
              </span>
              <em>
                Some information — such as your Internet Protocol (IP) address
                and/or browser and device characteristics — is collected
                automatically when you visit our Services.
              </em>
            </p>
            <p>
              We automatically collect certain information when you visit, use
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
            <p>The information we collect includes:</p>
            <ul>
              <li>
                <em>Log and Usage Data.</em> Log and usage data is
                service-related, diagnostic, usage and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type and settings and information
                about your activity in the Services (such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called ‘crash dumps’) and hardware settings).
              </li>
            </ul>
            <ul>
              <li>
                <em>Device Data.</em> We collect device data such as information
                about your computer, phone, tablet or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model Internet service provider
                and/or mobile carrier, operating system and system configuration
                information.
              </li>
            </ul>
            <ul>
              <li>
                <em>Location Data.</em> We collect location data such as
                information about your device’s location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the
                Services. For example, we may use GPS and other technologies to
                collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. Note however, if you choose to opt out, you may not be
                able to use certain aspects of the Services.
              </li>
            </ul>
            <p>
              <span>Information collected through our App</span>
            </p>
            <p> </p>
            <p>
              <span>
                <em>In Short: </em>
              </span>
              <em>We collect information regarding your </em>
              <em>mobile device, push notifications,</em>
              <em> when you use our App.</em>
            </p>
            <p>
              If you use our App, we also collect the following information:
            </p>
            <ul>
              <li>
                <em>Mobile Device Data. </em>We automatically collect device
                information (such as your mobile device ID, model and
                manufacturer), operating system, version information and system
                configuration information, device and application identification
                numbers, browser type and version, hardware model Internet
                service provider and/or mobile carrier, and Internet Protocol
                (IP) address (or proxy server). If you are using our App, we may
                also collect information about the phone network associated with
                your mobile device, your mobile device’s operating system or
                platform, the type of mobile device you use, your mobile
                device’s unique device ID and information about the features of
                our App you accessed.
              </li>
            </ul>
            <ul>
              <li>
                <em>Push Notifications. </em>We may request to send you push
                notifications regarding your account or certain features of the
                App. If you wish to opt-out from receiving these types of
                communications, you may turn them off in your device’s settings.
              </li>
            </ul>
            <p>
              This information is primarily needed to maintain the security and
              operation of our App, for troubleshooting and for our internal
              analytics and reporting purposes.
            </p>
            <p>
              <span>HOW DO WE USE YOUR INFORMATION?</span>
            </p>
            <p>
              <span>
                <em>In Short: </em>
              </span>
              <em>
                We process your information for purposes based on legitimate
                business interests, the fulfilment of our contract with you,
                compliance with our legal obligations, and/or your consent.
              </em>
            </p>
            <p>
              We use personal information collected via our Services for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </p>
            <p> </p>
            <p>We use the information we collect or receive:</p>
            <ul>
              <li>
                <span>To facilitate account creation and logon process.</span>{" "}
                If you choose to link your account with us to a third-party
                account (such as your Google or Facebook account), we use the
                information you allowed us to collect from those third parties
                to facilitate account creation and logon process for the
                performance of the contract. See the section below headed “
                <a href="https://tricket.in/privacy-policy/#sociallogins">
                  HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </a>
                ” for further information.
              </li>
            </ul>
            <ul>
              <li>
                <span>To post testimonials.</span> We post testimonials on our
                Services that may contain personal information. Prior to posting
                a testimonial, we will obtain your consent to use your name and
                the content of the testimonial. If you wish to update, or delete
                your testimonial, please feel free to contact us at
                team@tricket.in and be sure to include your name, testimonial
                location, and contact information.
              </li>
            </ul>
            <ul>
              <li>
                <span>Request feedback. </span>
                We may use your information to request feedback and to contact
                you about your use of our Services.
              </li>
            </ul>
            <ul>
              <li>
                <span>To enable user-to-user communications.</span> We may use
                your information in order to enable user-to-user communications
                with each user’s consent.
              </li>
            </ul>
            <ul>
              <li>
                <span>To manage user accounts. </span>
                We may use your information for the purposes of managing our
                account and keeping it in working order.
              </li>
            </ul>
            <ul>
              <li>
                <span>To send administrative information to you. </span>
                We may use your personal information to send you product,
                service and new feature information and/or information about
                changes to our terms, conditions, and policies.
              </li>
            </ul>
            <ul>
              <li>
                <span>To protect our Services. </span>
                We may use your information as part of our efforts to keep our
                Services safe and secure (for example, for fraud monitoring and
                prevention).
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  To enforce our terms, conditions and policies for business
                  purposes, to comply with legal and regulatory requirements or
                  in connection with our contract.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>To respond to legal requests and prevent harm. </span>
                If we receive any sort of legal request, we may need to inspect
                the data we hold to determine how to respond.
              </li>
            </ul>
            <ul>
              <li>
                <span>Fulfil and manage your orders. </span>
                We may use your information to fulfil and manage your orders,
                payments, returns, and exchanges made through the Services.
              </li>
            </ul>
            <ul>
              <li>
                <span>Administer prize draws and competitions.</span> We may use
                your information to administer prize draws and competitions when
                you elect to participate in our competitions.
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  To deliver and facilitate delivery of services to the user.
                </span>{" "}
                We may use your information to provide you with the requested
                service.
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  To respond to user inquiries/offer support to users.
                </span>{" "}
                We may use your information to respond to your inquiries and
                solve any potential issues you might have with the use of our
                Services.
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  To send you marketing and promotional communications.
                </span>{" "}
                We and/or our third-party marketing partners may use the
                personal information you send to us for our marketing purposes,
                if this is in accordance with your marketing preferences. For
                example, when expressing an interest in obtaining information
                about us or our Services, subscribing to marketing or otherwise
                contacting us, we will collect personal information from you.
                You can opt-out of our marketing emails at any time (see the “
                <a href="https://tricket.in/privacy-policy/#privacyrights">
                  WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
                ” below).
              </li>
              <li>
                <span>Deliver targeted advertising to you.</span> We may use
                your information to develop and display personalized content and
                advertising (and work with third parties who do so) tailored to
                your interests and/or location and to measure its effectiveness.
              </li>
            </ul>
            <p>
              <span>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span>
            </p>
            <p>
              <span>
                <em>In Short:</em>
              </span>
              <em>
                {" "}
                We only share information with your consent, to comply with
                laws, to provide you with services, to protect your rights, or
                to fulfil business obligations.
              </em>
            </p>
            <p>
              We may process or share your data that we hold based on the
              following legal basis:
            </p>
            <ul>
              <li>
                <span>Consent:</span> We may process your data if you have given
                us consent to use your personal information.
              </li>
            </ul>
            <ul>
              <li>
                <span>Legitimate Interests:</span> We may process your data when
                it is reasonably necessary to achieve our legitimate business
                interests.
              </li>
            </ul>
            <ul>
              <li>
                <span>Performance of a Contract:</span> Where we have entered
                into a contract with you, we may process your personal
                information to fulfil the terms of our contract.
              </li>
            </ul>
            <ul>
              <li>
                <span>Legal Obligations:</span> We may disclose your information
                where we are legally required to do so in order to comply with
                applicable law, governmental requests, a judicial proceeding,
                court order, or legal process, such as in response to a court
                order or any competent institution (including in response to
                public authorities to meet national security or law enforcement
                requirements).
              </li>
            </ul>
            <ul>
              <li>
                <span>Vital Interests:</span> We may disclose your information
                where we believe it is necessary to investigate, prevent, or
                take action regarding potential violations of our policies,
                suspected fraud, situations involving potential threats to the
                safety of anyone and illegal activities, or as evidence in
                litigation in which we are involved.
              </li>
            </ul>
            <p>
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </p>
            <ul>
              <li>
                <span>Business Transfers.</span> We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Vendors, Consultants and Other Third-Party Service Providers.
                </span>{" "}
                We may share your data with third-party vendors, service
                providers, contractors or agents who perform services for us or
                on our behalf and require access to such information to do that
                work. Examples include: payment processing, data analysis, email
                delivery, hosting services, customer service and marketing
                efforts. We may allow selected third parties to use tracking
                technology on the Services, which will enable them to collect
                data on our behalf about how you interact with our Services over
                time. This information may be used to, among other things,
                analyse and track data, determine the popularity of certain
                content, pages or features, and better understand online
                activity. Unless described in this notice, we do not share,
                sell, rent or trade any of your information with third parties
                for their promotional purposes.
              </li>
            </ul>
            <ul>
              <li>
                <span>Affiliates.</span> We may share your information with our
                affiliates, in which case we will require those affiliates to
                honour this privacy notice. Affiliates include our parent
                company and any subsidiaries, joint venture partners or other
                companies that we control or that are under common control with
                us.
              </li>
              <li>
                <span>Offer Wall.</span> Our App may display a third-party
                hosted “offer wall.” Such an offer wall allows third-party
                advertisers to offer virtual currency, gifts, or other items to
                users in return for the acceptance and completion of an
                advertisement offer. Such an offer wall may appear in our App
                and be displayed to you based on certain data, such as your
                geographic area or demographic information. When you click on an
                offer wall, you will be brought to an external website belonging
                to other persons and will leave our App. A unique identifier,
                such as your user ID, will be shared with the offer wall
                provider in order to prevent fraud and properly credit your
                account with the relevant reward. Please note that we do not
                control third-party websites and have no responsibility in
                relation to the content of such websites. The inclusion of a
                link towards a third-party website does not imply an endorsement
                by us of such website. Accordingly, we do not make any warranty
                regarding such third-party websites and we will not be liable
                for any loss or damage caused by the use of such websites. In
                addition, when you access any third-party website, please
                understand that your rights while accessing and using those
                websites will be governed by the privacy notice and terms of
                service relating to the use of those websites.
              </li>
            </ul>
            <p>
              <span>WHO WILL YOUR INFORMATION BE SHARED WITH?</span>
            </p>
            <p>
              <span>
                <em>In Short:</em>
              </span>
              <em>
                {" "}
                We only share information with the following categories of third
                parties.
              </em>
            </p>
            <p>
              We share and disclose your information with third parties. If we
              have processed your data based on your consent and you wish to
              revoke your consent, please contact us using the contact details
              provided in the section below titled “
              <a href="https://tricket.in/privacy-policy/#contact">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              “. Here are few third parties with which we may share your
              information with your consent:
            </p>
            <ul>
              <li>Ad Networks</li>
            </ul>
            <ul>
              <li>Cloud Computing Services</li>
            </ul>
            <ul>
              <li>Communication &amp; Collaboration Tools</li>
            </ul>
            <ul>
              <li>Data Analytics Services</li>
            </ul>
            <ul>
              <li>Data Storage Service Providers</li>
            </ul>
            <ul>
              <li>Finance &amp; Accounting Tools</li>
            </ul>
            <ul>
              <li>Government Entities</li>
            </ul>
            <ul>
              <li>Website Hosting Service Providers</li>
            </ul>
            <ul>
              <li>User Account Registration &amp; Authentication Services</li>
            </ul>
            <ul>
              <li>Testing Tools</li>
            </ul>
            <ul>
              <li>Social Networks</li>
            </ul>
            <ul>
              <li>Sales &amp; Marketing Tools</li>
            </ul>
            <ul>
              <li>Retargeting Platforms</li>
            </ul>
            <ul>
              <li>Product Engineering &amp; Design Tools</li>
            </ul>
            <ul>
              <li>Performance Monitoring Tools</li>
              <li>Payment Processors</li>
            </ul>
            <p>
              <span>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
            </p>
            <p>
              <span>
                <em>In Short: </em>
              </span>
              <em>
                We may use cookies and other tracking technologies to collect
                and store your information.
              </em>
            </p>
            <p>
              We may use cookies and similar tracking technologies to access or
              store information. Specific information about how we use such
              technologies and how you can refuse certain cookies is set out in
              our Cookie Notice.
            </p>
            <p>
              <span>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
            </p>
            <p>
              <span>
                <em>In Short: </em>
              </span>
              <em>
                If you choose to register or log in to our services using a
                social media account, we may have access to certain information
                about you.
              </em>
            </p>
            <p>
              Our Services offers you the ability to register and login using
              your third-party social media account details. Where you choose to
              do this, we will receive certain profile information about you
              from your social media provider. The profile information we
              receive may vary depending on the social media provider concerned,
              but will often include your name, email address, friends list,
              profile picture as well as other information you choose to make
              public on such social media platform.
            </p>
            <p>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use and share your personal information, and how you
              can set your privacy preferences on their sites and apps.
            </p>
            <p>
              <span>SECURITY</span>
            </p>
            <p>
              We have implemented security measures designed to protect the
              personal information you share with us, including physical,
              electronic and procedural measures. Among other things, we
              regularly monitor our systems for possible vulnerabilities and
              attacks.
            </p>
            <p>
              Regardless of the measures and efforts taken by us, the
              transmission of information via internet, email or text message is
              not completely secure. We do not guarantee the absolute protection
              and security of your personal information or any other User
              Content you upload, publish or otherwise share with us or anyone
              else.
            </p>
            <p>
              We therefore encourage you to avoid providing us or anyone with
              any sensitive information of which you believe its disclosure
              could cause you substantial or irreparable harm.
            </p>
            <p>
              If you have any questions regarding the security of our Website,
              Application and Services, you are welcome to contact us at{" "}
              <a href="mailto:team@tricket.in">team@tricket.in</a>
            </p>
            <p>
              <span>AMENDMENTS</span>
            </p>
            <p>
              Our Policy may change from time to time. We will post any Policy
              changes on our Website, Application, and if the changes are
              significant, we may consider providing a more explicit notice
              (including, for certain services, email notification of Policy
              changes).
            </p>
            <p>
              <span>Account Information</span>
            </p>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul>
              <li>
                Log in to your account settings and update your user account.
              </li>
            </ul>
            <ul>
              <li>Contact us using the contact information provided.</li>
            </ul>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </p>
            <p>
              <span>Cookies and similar technologies:</span> Most Web browsers
              are set to accept cookies by default. If you prefer, you can
              usually choose to set your browser to remove cookies and to reject
              cookies. If you choose to remove cookies or reject cookies, this
              could affect certain features or services of our Services.
            </p>
            <p>
              <span>Opting out of email marketing:</span> You can unsubscribe
              from our marketing email list at any time by clicking on the
              unsubscribe link in the emails that we send or by contacting us
              using the details provided below. You will then be removed from
              the marketing email list — however, we may still communicate with
              you, for example to send you service-related emails that are
              necessary for the administration and use of your account, to
              respond to service requests, or for other non-marketing purposes.
              To otherwise opt-out, you may:
            </p>
            <ul>
              <li>Access your account settings and update your preferences.</li>
            </ul>
            <ul>
              <li>Contact us using the contact information provided.</li>
            </ul>
            <p>
              <span>DO WE MAKE UPDATES TO THIS NOTICE?</span>
            </p>
            <p>
              <span>
                <em>In Short: </em>
              </span>
              <em>
                Yes, we will update this notice as necessary to stay compliant
                with relevant laws.
              </em>
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated “Revised” date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
            <p>
              <span>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
            </p>
            <p>
              If you have questions or comments about this notice, you may email
              us at team@tricket.in{" "}
            </p>
            <p>
              <span>
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </span>
            </p>
            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please feel free to contact us at{" "}
              <a href="mailto:team@tricket.in">team@tricket.in</a>
            </p>
            <ol>
              <li>ACCEPTANCE OF THIS POLICY</li>
            </ol>
            <p>
              We assume that all Users of this website and application have
              carefully read this document and agree to its contents. If someone
              does not agree with this Policy, they should refrain from using
              our website and application. We reserve the right to change our
              Policy at any time and inform by using the way as indicated in
              Section 14. Continued use of this website and application implies
              acceptance of the revised Policy.
            </p>
            <ol start="2">
              <li>FURTHER INFORMATION</li>
            </ol>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at team@tricket.in.
            </p>
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
