import React, { useState } from "react";
import classes from "./AboutUs.module.css";
import img from "../../assets/Group 1047.png";
import fb from "../../assets/facebook.png";
import x from "../../assets/x.png";
import insta from "../../assets/insta.png";
import linkedin from "../../assets/linkedin.png";
import rocket from "../../assets/rocket-launch.png";
import castle from "../../assets/castle.png";
import telescope from "../../assets/telescope.png";
import Modal from "../../Components/Modals/Modal";
const AboutUs = () => {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };
  const isMacOS = navigator.platform.toUpperCase().includes("MAC");
  const isAndroid = /Android/.test(navigator.userAgent);
  const isiPhone = /iPhone/.test(navigator.userAgent);
  const isWindows = /Win/.test(navigator.platform);
  const isMacOSOriPhone = isMacOS || isiPhone;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.left__div}>
          <h2>About Us</h2>
          <p>
            Tricket is all about embracing the thrill of cricket and putting
            your analytical acumen to the test. When you join Tricket, you enter
            a realm where your cricket knowledge truly matters. Our platform
            provides real-time data, including match situations, player
            statistics, pitch reports, and much more.
          </p>
          {isMacOSOriPhone ? (
            <a
              href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
                width:"100%"
              }}
            >
              <button>Join Our Tribe Now</button>
            </a>
          ) : (
            <a
              href="/Tricket.apk"
              download
              onClick={openModal}
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
                width:"100%"
              }}
            >
              <button>Join Our Tribe Now</button>
            </a>
          )}
          {/* {isMacOS && <a
              href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965" target="_blank"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              <button>Join Our Tribe Now1</button>
            </a>}
            
            {isiPhone && <a
              href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965" target="_blank"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              <button>Join Our Tribe Now2</button>
            </a>}

            {isWindows && <a
              href="/Tricket.apk" download  onClick={openModal}
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
             <button>Join Our Tribe Now3</button>
            </a>}
            {isAndroid && <a
              href="/Tricket.apk" download  onClick={openModal}
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
             <button>Join Our Tribe Now4</button>
            </a>} */}

          {/* <button>Join Our Tribe Now</button> */}
          <div className={classes.social}>
            <caption>Find us on</caption>
            <div className={classes.social__icons__div}>
              <div className={classes.social__icon}>
                <a
                  href="https://www.facebook.com/tricketofficial"
                  target="_blank"
                >
                  <img src={fb} alt="facebook" />
                </a>
              </div>
              <div className={classes.social__icon}>
                <a href="https://twitter.com/TricketTeam" target="_blank">
                  <img src={x} alt="x.com" />
                </a>
              </div>
              <div className={classes.social__icon}>
                <a
                  href="https://www.instagram.com/tricketofficial/"
                  target="_blank"
                >
                  <img src={insta} alt="insta" />
                </a>
              </div>
              <div className={classes.social__icon}>
                <a
                  href="http://https://www.linkedin.com/company/tricket/"
                  target="_blank"
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.right__div}>
          <img src={img} />
        </div>
      </div>

      <div className={classes.container__2}>
        <div>
        <h2>Our Story</h2>
        {/* <h2 className={classes.so_far}>So Far</h2> */}
        </div>
        <div className={classes.story__container}>
          <div
            className={`${
             classes.story__div__even
            }`}
            // className={`${
            //   isHovered1 ? classes.story__div__odd : classes.story__div__even
            // }`}
            onMouseEnter={handleMouseEnter1}
            onMouseLeave={handleMouseLeave1}
          >
            <div
              className={`${
                classes.inner__div__even
              }`}
            >
              <img src={rocket} alt="rocket" />
            </div>
            <h4>Launched on 2023</h4>
            <p>
              An initiative by Helpen.In backed by Bihar Govt. to bring the
              simplest version of fantasy gaming.
            </p>
          </div>
          <div
            className={`${
              classes.story__div__odd 
            }`}
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
          >
            <div
              className={`${
                classes.inner__div__odd
              }`}
            >
              <img src={telescope} alt="telescope" />
            </div>
            <h4>Our Vision</h4>
            <p>
              Tricket envisions revolutionizing cricket gaming, offering a
              skill-based experience where accurate predictions lead to
              thrilling wins and community celebration.
            </p>
          </div>
          <div
            className={`${
              classes.story__div__even
            }`}
            onMouseEnter={handleMouseEnter3}
            onMouseLeave={handleMouseLeave3}
          >
            <div
              className={`${
                classes.inner__div__even
              }`}
            >
              <img src={castle} alt="castle" />
            </div>
            <h4>Our Mission</h4>
            <p>
              Empower cricket fans with skillful gaming. Tricket’s mission is to
              create a global community celebrating precise predictions and
              thrilling victories.
            </p>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default AboutUs;
